<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: index.vue
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:01
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:35:55
 Modificado por: pedro.rosa
-->
<template>
  <div class="center404template">
    <v-card
      elevation="0"
      style="padding: 25px; margin: 25px"
    >
      <span class="text-h2 font-weight-medium text-secondary">404 </span>
      <span class="text-h5 font-weight-medium text-secondary"> Ops... </span><br>
      <span class="text-h5 font-weight-medium text-secondary">
        Não conseguimos encontrar essa página. </span><br>
      <span
        class="voltar"
        @click="$router.push('/')"
      > Voltar ao inicio </span>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
.center404template {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.voltar {
  cursor: pointer;
  color: rgb(75, 75, 235);
}
.voltar:hover {
  color: rgb(8, 8, 240);
}
</style>